// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message {
    color: red;
}

.success-message {
    color: green;
}`, "",{"version":3,"sources":["webpack://./src/routes/register/register.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".error-message {\n    color: red;\n}\n\n.success-message {\n    color: green;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
