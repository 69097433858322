/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, ChangeEvent, useEffect } from "react";
import axios from "axios";
import "./fileupload.css";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { AppDispatch } from "../../store/store";
import { logout } from "../../store/user/user.reducer";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoVideo } from "@fortawesome/free-solid-svg-icons";
import BASE_URL from "../../utils/apiurl";

interface DeepgramResponse {
  transcript?: string;
  [key: string]: any;
}

interface FileUploadProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cid?: string;
  fetchLessonById: () => void;
  fetchLessonList: () => void;
  setNotif:any;
}

const FileUpload: React.FC<FileUploadProps> = ({
  open,
  setOpen,
  cid,
  fetchLessonById,
  fetchLessonList,
  setNotif,
}) => {
  const recorderControls = useAudioRecorder();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const email = user.email;
  const [file, setFile] = useState<File | null>(null);
  const [response, setResponse] = useState<DeepgramResponse | null>(null);
  const [transcription, setTranscription] = useState<string>("");
  const [summary, setSummary] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [standard, setStandard] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [chapter, setChapter] = useState<any>(null);
  const [link, setLink] = useState<string>("");
  const [letter, setLetter] = useState<string>("");
  const [lessonName, setLessonName] = useState<string>("")
  const [chapterOptions, setChapterOptions] = useState<any>([]);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

  const addAudioElement = (blob: Blob) => {
    setAudioBlob(blob);
    console.log('File is set, audio blob')
  };

  const handleSaveAudio = () => {
    if (audioBlob) {
      const newFile = new File([audioBlob], (lessonName ? lessonName : "recording"), {
        type: "audio/webm",
      });
      setFile(newFile);
      console.log('Set new file.')
      console.log(file);
    }
  };

  const onLogOut = async () => {
    try {
      const resultAction = await dispatch(logout({ email }));
      console.log(resultAction.payload);
      if (logout.fulfilled.match(resultAction)) {
        console.log("Success:", resultAction.payload);
      } else {
        console.log("Failed to logout");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const chapterCount = async () => {
    const formData = new FormData();
    formData.append("standard", standard);
    formData.append("subject", subject);
    try {
      const res = await axios.post(
        BASE_URL+"get-chapter-names/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      const chapters = res.data.chapters;
      setChapterOptions(chapters);
      setChapter(chapters[0])
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        alert("Session expired. Please log in again.");
        onLogOut();
      } else {
        console.log(error);
      }
    }
  };

  const fetchValues = async () => {
    try {
      const res = await axios.get(`${BASE_URL}get-values/${cid}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      setSubject(res.data.subject);
      setStandard(res.data.standard);
      setLetter(res.data.letter);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        alert("Session expired. Please log in again.");
        onLogOut();
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchValues();
    console.log(standard);
    console.log(subject);
    console.log(letter);
  }, []);

  useEffect(() => {
    chapterCount();
  }, [standard, subject]);

  useEffect(() => {
    handleSaveAudio();
  }, [audioBlob])

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleChapter = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setChapter(e.target.value);
    }
  };

  const fetchLatestTranscription = async () => {
    try {
      const res = await axios.get<DeepgramResponse>(
        BASE_URL+"transcribe/",
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      setTranscription(res.data.transcript || "");
      setSummary(res.data.summary || "");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        alert("Session expired. Please log in again.");
        onLogOut();
      } else {
        console.log(error);
      }
    } finally {
      setLoading(false);
      setOpen(false);
      setNotif(true);
      fetchLessonList();
      fetchLessonById();
    }
  };

  const readFile = async (link: string) => {
    setLoading(true);

    const formData = new FormData();
    if (file && standard && subject && chapter) {
      setLoading(true);
      formData.append("link", link);
      formData.append("standard", standard);
      formData.append("subject", subject);
      formData.append("chapter", chapter.chapter);
      try {
        const res = await axios.post(
          BASE_URL+"create-chapter/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );
        console.log(res.data);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          alert("Session expired. Please log in again.");
          onLogOut();
        } else {
          console.log(error);
        }
        setLoading(false);
      }
    }
  };

  const transcribe = async (link: string) => {
    const formData = new FormData();
    if (file && standard && subject && letter && chapter) {
      formData.append("file", file);
      formData.append("link", link);
      formData.append("letter", letter);
      formData.append("name", lessonName)
      try {
        const res = await axios.post<DeepgramResponse>(
          BASE_URL+"transcribe/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );
        setResponse(res.data);
        fetchLatestTranscription();
        console.log(response);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          alert("Session expired. Please log in again.");
          onLogOut();
        } else {
          console.log(error);
        }
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (link: any) => {
    const constructedLink = link;
    await setLink(constructedLink);
    console.log("Constructed Link:", constructedLink);
    try {
      await readFile(constructedLink);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await transcribe(constructedLink);
    } catch (error) {
      console.error("Error in handling submission:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="fixed inset-0 z-50"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      />

      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start w-full">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Upload Lesson
                  </DialogTitle>
                  <div className="mt-10 h-80 flex flex-col">
                    <Menu as="div" className="relative inline-block text-left">
                      <div className="flex items-center mb-4">
                        <label
                          htmlFor="chapter"
                          className="text-sm font-bold leading-6 text-neutral-900 min-w-fit pr-4"
                        >
                          Select your chapter:
                        </label>
                        <MenuButton className="flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          {chapter ? `${chapter.chapter}` : "Chapter"}
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 h-5 w-5 text-gray-400"
                          />
                        </MenuButton>
                      </div>
                      <MenuItems className="absolute right-0 z-50 top-10 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                        <div className="max-h-[30vh] overflow-y-auto">
                          <div className="py-1">
                            {chapterOptions.map((chapter: any) => (
                              <MenuItem>
                                {({ active }) => (
                                  <a
                                    onClick={() => setChapter(chapter)}
                                    className={`block px-4 py-2 text-sm ${
                                      active ? "bg-gray-100" : ""
                                    }`}
                                  >
                                    {chapter.chapter}
                                  </a>
                                )}
                              </MenuItem>
                            ))}
                          </div>
                        </div>
                      </MenuItems>
                    </Menu>
                    <Menu as="div" className="relative inline-block text-left">
                      <div className="flex items-center mb-4">
                          <label
                          htmlFor="chapter"
                          className="text-sm font-bold leading-6 text-neutral-900 min-w-fit pr-4"
                          >
                          Lesson name:
                          </label>
                          <input
                              type="text"
                              required
                              value={lessonName}
                              onChange={(e) => setLessonName(e.target.value)}
                              placeholder="Lesson"
                              className="block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                      </div>
                    </Menu>
                    <Menu as="div" className="relative inline-block text-left">
                      <div className="flex justify-center items-center mt-10">
                        <label
                          htmlFor="file-upload"
                          className="flex items-center justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          <FontAwesomeIcon
                            icon={faPhotoVideo}
                            className="h-5 w-5"
                          />{" "}
                          <span className="truncate">
                            {file?.name || "Upload media file"}
                          </span>
                          <input
                            type="file"
                            id="file-upload"
                            className="file-upload-input"
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>
                    </Menu>
                    <div id="or" className="my-3">
                      {" "}
                      OR{" "}
                    </div>
                    <Menu
                      as="div"
                      className="relative flex justify-between items-center"
                    >
                      <div className="flex w-full flex-col items-center justify-center">
                        <AudioRecorder
                          onRecordingComplete={addAudioElement}
                          recorderControls={recorderControls}
                        />
                        {!recorderControls.isRecording && (
                          <span className="font-semibold text-gray-700 mt-3">
                            Record a lesson
                          </span>
                        )}
                      </div>
                      {recorderControls.isRecording && (
                        <button
                          type="button"
                          onClick={() => {
                            recorderControls.stopRecording();
                          }}
                          className="ml-4  self-baseline inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          Save
                        </button>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault()
                  handleSubmit(chapter.s3_link)
                }}
                className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
              >
                {loading ? (
                  <span className="animate-pulse">Creating...</span>
                ) : (
                  "Create"
                )}
              </button>
              <button
                type="button"
                onClick={() => setOpen(false)}
                disabled={loading}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default FileUpload;
