import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../store/store";
import { logout } from "../../store/user/user.reducer";
import { selectCurrentUser } from "../../store/user/user.selector";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Modal from "../modal/modal";
import LoginModal from "../login-modal/login-modal";
import Delete from "../delete/delete";
import axios from "axios";

interface Classroom {
  _id: string;
  standard: string;
  subject: string;
  letter: string;
  teacher: string;
}

const ClassList: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const type = user.type;
  const email = user.email;
  const standard = user.standard;
  const letter = user.letter;
  const [list, setList] = useState<Classroom[] | null>(null);
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [classroomToDelete, setClassroomToDelete] = useState<Classroom | null>(null); // New state to track which classroom to delete
  const gradientArray = ["from-violet-500 to-fuchsia-500", "from-cyan-500 to-blue-500", "from-red-600 to-red-500", "from-gray-800 to-gray-700"];
  const navigate = useNavigate();

  const onLogOut = async () => {
    try {
      const resultAction = await dispatch(logout({ email }));
      if (logout.fulfilled.match(resultAction)) {
        console.log("Success:", resultAction.payload);
      } else {
        console.log("Failed to logout");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClassroomList = async () => {
    try {
      const res = await axios.get(`https://api.vidya-ai.com/get-classes/${user.id}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      setList(res.data.classrooms);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        navigate("");
        onLogOut();
      } else {
        console.log(error);
      }
    }
  };

  const deleteClassroom = async () => {
    if (!classroomToDelete) return;
    try {
      const res = await axios.delete(`https://api.vidya-ai.com/delete-class/${classroomToDelete._id}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      console.log(res.data.message);
      fetchClassroomList();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        navigate("");
        onLogOut();
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchClassroomList();
  }, [user]);

  useEffect(() => {
    setOpenDetails(true);
  }, []);

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="flex justify-between items-center mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-left text-gray-900">
          Hello, {user.name}
        </h1>
        {(type === "teacher" || type === "admin" || type === "principal") && (
          <button
            onClick={() => setOpen(!open)}
            className="text-lg px-4 py-2 rounded-md bg-gradient-to-br from-amber-500 to-orange-500 text-white flex items-center justify-center shadow-lg hover:bg-blue-600 transition-colors"
          >
            Create class
          </button>
        )}
      </div>
      {list && (
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-6 mt-6 max-h-[31.25rem] overflow-y-auto">
          {list.map((classroom) => (
            <div
              key={classroom._id}
              className="relative bg-white rounded-lg shadow-lg shadow-sm ring-1 ring-inset ring-gray-200 hover:shadow-xl transition-shadow cursor-pointer"
            >
              <div className={`bg-gradient-to-br ${gradientArray[Math.floor(Math.random() * 4)]} h-[40%]`} onClick={() => navigate(`/${classroom._id}`)}>
                <div className="p-5">
                  <p className="text-sm font-semi text-left">
                    {classroom.standard}th Standard Class {classroom.letter}
                  </p>
                  <h2 className="text-l text-left font-semibold text-white">{classroom.subject}</h2>
                  <p className="text-sm text-justify font-semi mb-40 text-white">Teacher: {classroom.teacher}</p>
                </div>
              </div>
              {(type === "teacher" || type === "admin") && (
                <div className="absolute bottom-2 right-2">
                  <Menu as="div" className="relative inline-block text-left">
                    <MenuButton className="inline-flex justify-center text-gray-400 hover:text-gray-600">
                      <FontAwesomeIcon icon={faEllipsisV} className="h-5 w-5" />
                    </MenuButton>
                    <MenuItems className="absolute right-0 bottom-10 z-50 mt-2 w-36 origin-bottom-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem>
                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={(e) => {
                            e.preventDefault();
                            setClassroomToDelete(classroom); // Set the classroom to be deleted
                            setDeleteOpen(true);
                          }}
                        >
                          Delete
                        </a>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {openDetails === true && type === "student" && standard.length === 0 && letter.length === 0 && (
        <LoginModal open={openDetails} setOpen={setOpenDetails} />
      )}
      {open === true && (
        <Modal open={open} setOpen={setOpen} callbackFetch={() => fetchClassroomList()} />
      )}
      {deleteOpen === true && (
        <Delete open={deleteOpen} setOpen={setDeleteOpen} deleteLesson={deleteClassroom} />
      )}
    </div>
  );
};

export default ClassList;
