/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './landing-page.css'
import { Link } from 'react-router-dom'

export default function LandingPage() {
  return (
    <div className="background">
      <div className="relative isolate px-6 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
          <img src="/lotus.svg" alt="Lotus" className="inline h-16 w-auto filter invert" />
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl font-serif">
              VIDYA.AI
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-100 font-serif">
            Enhancing Education with Your Digital Teaching Assistant.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to="/login"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm transition-all duration-300 ease-in-out hover:bg-gradient-to-r from-amber-500 to-orange-500 hover:text-white focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Get started
              </Link>
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
        </div>
      </div>
    </div>
  );
}
