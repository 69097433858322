/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  FormEvent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import axios from "axios";
import { logout } from "../../store/user/user.reducer";
import { AppDispatch } from "../../store/store";

interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callbackFetch: () => void;
}

const Modal: React.FC<ModalProps> = ({ open, setOpen, callbackFetch }) => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const teacher = user.name;
  const email = user.email;
  const [standard, setStandard] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [letter, setLetter] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);

  const onLogOut = async () => {
    try {
      const resultAction = await dispatch(logout({ email }));
      console.log(resultAction.payload);
      if (logout.fulfilled.match(resultAction)) {
        console.log("Success:", resultAction.payload);
      } else {
        console.log("Failed to logout");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = new FormData();
    if (standard && subject && letter) {
      try {
        setSubmitting(true);
        formData.append("standard", standard);
        formData.append("subject", subject);
        formData.append("letter", letter);
        formData.append("teacher", teacher);

        const token = user.access_token;

        const res = await axios.post(
          "https://api.vidya-ai.com/create-class/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.data) {
          setOpen(false);
          callbackFetch();
        }

        console.log(res.data);
      } catch (error) {
        setSubmitting(false);
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          alert("Session expired. Please log in again.");
          onLogOut();
        } else {
          console.log(error);
        }
      }
    }
  };

  const handleStandardSelect = (value: string) => setStandard(value);
  const handleSubjectSelect = (value: string) => setSubject(value);
  const handleLetterSelect = (value: string) => setLetter(value);

  useEffect(() => {
    console.log(standard);
    console.log(subject);
    console.log(letter);
  }, [standard, subject, letter]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="fixed inset-0 z-50"
    >
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start w-full">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Create Classroom
                  </DialogTitle>
                  <div className="mt-2">
                    <Menu as="div" className="relative block text-left">
                      <div className="flex items-center mb-4">
                        <label
                          htmlFor="chapter"
                          className="text-sm font-bold leading-6 text-neutral-900 min-w-fit pr-4"
                        >
                          Select the standard:
                        </label>
                        <MenuButton className="flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          {standard ? `${standard}th Standard` : "Standard"}
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 h-5 w-5 text-gray-400"
                          />
                        </MenuButton>
                      </div>
                      <MenuItems className="absolute right-0 top-8 z-50 mt-2 w-[50%] origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <MenuItem>
                            {({ active }) => (
                              <a
                                onClick={() => handleStandardSelect("6")}
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100" : ""
                                }`}
                              >
                                6th Standard
                              </a>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              <a
                                onClick={() => handleStandardSelect("7")}
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100" : ""
                                }`}
                              >
                                7th Standard
                              </a>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              <a
                                onClick={() => handleStandardSelect("8")}
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100" : ""
                                }`}
                              >
                                8th Standard
                              </a>
                            )}
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                    <Menu as="div" className="relative block text-left">
                      <div className="flex items-center mb-4">
                        <label
                          htmlFor="chapter"
                          className="text-sm font-bold leading-6 text-neutral-900 min-w-fit pr-4"
                        >
                          Select the subject:
                        </label>
                        <MenuButton className="flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          {subject || "Subject"}
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 h-5 w-5 text-gray-400"
                          />
                        </MenuButton>
                      </div>
                      <MenuItems className="absolute right-0 top-8 z-50 mt-2 w-[50%] origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <MenuItem>
                            {({ active }) => (
                              <a
                                onClick={() => handleSubjectSelect("English")}
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100" : ""
                                }`}
                              >
                                English
                              </a>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              <a
                                onClick={() =>
                                  handleSubjectSelect("Mathematics")
                                }
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100" : ""
                                }`}
                              >
                                Mathematics
                              </a>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                onClick={() => handleSubjectSelect("Science")}
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100" : ""
                                }`}
                              >
                                General Science
                              </a>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                onClick={() =>
                                  handleSubjectSelect("History and Civics")
                                }
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100" : ""
                                }`}
                              >
                                History and Civics
                              </a>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                onClick={() => handleSubjectSelect("Geography")}
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100" : ""
                                }`}
                              >
                                Geography
                              </a>
                            )}
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                    <Menu as="div" className="relative inline-block text-left">
                      <div className="flex items-center mb-4">
                        <label
                          htmlFor="chapter"
                          className="text-sm font-bold leading-6 text-neutral-900 min-w-fit pr-4"
                        >
                          Select the division:
                        </label>
                        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          {letter || "Letter"}
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 h-5 w-5 text-gray-400"
                          />
                        </MenuButton>
                      </div>
                      <MenuItems className="absolute right-0 top-8 z-50 mt-2 w-[50%] origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <MenuItem>
                            {({ active }) => (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                onClick={() => handleLetterSelect("A")}
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100" : ""
                                }`}
                              >
                                A
                              </a>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                onClick={() => handleLetterSelect("B")}
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100" : ""
                                }`}
                              >
                                B
                              </a>
                            )}
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={submitting}
                className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
              >
                {submitting ? (
                  <span className="animate-pulse">Creating...</span>
                ) : (
                  "Create"
                )}
              </button>
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
