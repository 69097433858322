// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./classroom.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    z-index: 20;
}

.bg-gradiant{
  background: linear-gradient(180.66deg, #EFF5FF 0.55%, #F4FBFF 86.73%);
}

.background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the rgba values for the desired tint */
  z-index: 1; /* Ensure it is above the background image but below content */
}

.header-content {
  position: relative; /* Ensures this content is above the tint layer */
  z-index: 2; /* Place content above the tint */
  text-align: center; /* Center the text */
  color: white; /* Ensure text color is readable */
}

.lotus-image {
  position: absolute;
  filter: brightness(0) invert(1);
  top: 10%; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 150px; /* Adjust size as needed */
  height: auto;
  z-index: 1; /* Ensure it's above the background but below the content */
}`, "",{"version":3,"sources":["webpack://./src/routes/landing-page/landing-page.css"],"names":[],"mappings":"AAAA;IACI,yDAAwC;IACxC,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,aAAa;IACb,WAAW;IACX,WAAW;AACf;;AAEA;EACE,qEAAqE;AACvE;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,gDAAgD;EACtF,UAAU,EAAE,8DAA8D;AAC5E;;AAEA;EACE,kBAAkB,EAAE,iDAAiD;EACrE,UAAU,EAAE,iCAAiC;EAC7C,kBAAkB,EAAE,oBAAoB;EACxC,YAAY,EAAE,kCAAkC;AAClD;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,QAAQ,EAAE,qBAAqB;EAC/B,SAAS;EACT,2BAA2B;EAC3B,YAAY,EAAE,0BAA0B;EACxC,YAAY;EACZ,UAAU,EAAE,2DAA2D;AACzE","sourcesContent":[".background {\n    background-image: url('./classroom.png');\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    height: 100vh;\n    width: 100%;\n    z-index: 20;\n}\n\n.bg-gradiant{\n  background: linear-gradient(180.66deg, #EFF5FF 0.55%, #F4FBFF 86.73%);\n}\n\n.background::before {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); /* Adjust the rgba values for the desired tint */\n  z-index: 1; /* Ensure it is above the background image but below content */\n}\n\n.header-content {\n  position: relative; /* Ensures this content is above the tint layer */\n  z-index: 2; /* Place content above the tint */\n  text-align: center; /* Center the text */\n  color: white; /* Ensure text color is readable */\n}\n\n.lotus-image {\n  position: absolute;\n  filter: brightness(0) invert(1);\n  top: 10%; /* Adjust as needed */\n  left: 50%;\n  transform: translateX(-50%);\n  width: 150px; /* Adjust size as needed */\n  height: auto;\n  z-index: 1; /* Ensure it's above the background but below the content */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
