import React, { useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/user/user.selector';
import { logout } from '../../store/user/user.reducer';
import { AppDispatch } from '../../store/store';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  const onLogOut = async () => {
    try {
      const email = user.email;
      const resultAction = await dispatch(logout({ email }));
      if (logout.fulfilled.match(resultAction)) {
        console.log('Success:', resultAction.payload);
      } else {
        console.log('Failed to logout');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = async () => {
    onLogOut();
  };

  return (
    <header className="bg-white fixed top-0 w-full z-50">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
        <div className="flex items-center">
          <ul className="flex space-x-4">
            <li>
              <Link to="/" className="text-sm font-semibold leading-6 text-gray-900">Home</Link>
            </li>
            {user.type === 'admin' && (
              <><li>
                <Link to="/requests" className="text-sm font-semibold leading-6 text-gray-900">Requests</Link>
              </li>
              <li>
                <Link to="/users" className="text-sm font-semibold leading-6 text-gray-900">Users</Link>
              </li></>
            )}
          </ul>
        </div>

        <div className="lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <ul className="flex space-x-4">
            {!user.isAuthenticated && (
              <li>
                <Link to="/register" className="text-sm font-semibold leading-6 text-gray-900">Register</Link>
              </li>
            )}
            <li>
              {user.isAuthenticated && user ? (
                <Link to="/login" onClick={handleLogout} className="text-sm font-semibold leading-6 text-gray-900">Log Out</Link>
              ) : (
                <Link to="/login" className="text-sm font-semibold leading-6 text-gray-900">Log In</Link>
              )}
            </li>
          </ul>
        </div>
      </nav>

      <Outlet />

      <div className="lg:hidden">
        {mobileMenuOpen && (
          <div className="fixed inset-0 z-10">
            <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <button
                  type="button"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <ul className="space-y-2 py-6">
                    <li>
                      <Link to="/" className="block rounded-lg py-2 pl-6 pr-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Home</Link>
                    </li>
                    <li>
                      <Link to="/transcribe" className="block rounded-lg py-2 pl-6 pr-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Transcribe</Link>
                    </li>
                    {!user.isAuthenticated && (
                      <li>
                        <Link to="/register" className="block rounded-lg py-2 pl-6 pr-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Register</Link>
                      </li>
                    )}
                    <li>
                      {user.isAuthenticated && user ? (
                        <Link to="/login" onClick={handleLogout} className="block rounded-lg py-2 pl-6 pr-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log Out</Link>
                      ) : (
                        <Link to="/login" className="block rounded-lg py-2 pl-6 pr-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log In</Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default NavBar;
