import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { AppDispatch } from "../../store/store";
import { signup } from "../../store/user/user.reducer";

interface Requests {
  _id: string;
  name: string;
  email: string;
  password: string;
}

const RequestsPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [studentRequests, setStudentRequests] = useState<Requests[] | null>(null);
  const [teacherRequests, setTeacherRequests] = useState<Requests[] | null>(null);
  const [activeTab, setActiveTab] = useState<"student" | "teacher">(
    "student"
);
  const type = user.type;

  const getRequests = async () => {
    try {
      const res = await axios.get("https://api.vidya-ai.com/get-requests/", {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      setStudentRequests(res.data.students);
      setTeacherRequests(res.data.teachers);
    } catch (error) {
      console.log(error);
    }
  };

  const onSignUp = async (
    name: string,
    email: string,
    password: string,
    type: string,
    standard: string,
    letter: string,
    school_id: string
  ) => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('type', type)
      formData.append('standard', standard)
      formData.append('letter', letter)
      formData.append('school_id', school_id)
      const response = await axios.post('https://api.vidya-ai.com/signup/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRequest = async (request_id: string) => {
    try {
      const res = await axios.delete(
        `https://api.vidya-ai.com/delete-requests/${request_id}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRequests();
  }, []);


  return (
    <div className="max-w-7xl mx-auto p-4">
      {type === "admin"
       ? (
        <>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <div className="flex justify-start mb-5">
              <div
                className={`text-base text-gray-900 py-2 px-5 mx-1 cursor-pointer border-b-2 ${
                  activeTab === "student"
                    ? "border-black"
                    : "border-transparent"
                } hover:border-gray-300`}
                onClick={() => setActiveTab("student")}
              >
                Student
              </div>
              <div
                className={`text-base text-gray-900 py-2 px-5 mx-1 cursor-pointer border-b-2 ${
                  activeTab === "teacher"
                    ? "border-black"
                    : "border-transparent"
                } hover:border-gray-300`}
                onClick={() => setActiveTab("teacher")}
              >
                Teacher
              </div>
            </div>
          </div>
  
          {(studentRequests || teacherRequests) ? (
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {activeTab === 'student' ? studentRequests?.map((request) => (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={request._id}>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {request.name}
                      </th>
                      <td className="px-6 py-4">{request.email}</td>
                      <td className="px-6 py-4 flex gap-4">
                        <button
                          type="button"
                          onClick={async () => {
                            await onSignUp(
                              request.name,
                              request.email,
                              request.password,
                              "student",
                              "",
                              "",
                              user.school_id
                            );
                            await deleteRequest(request._id);
                            getRequests();
                          }}
                          className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                        >
                          Accept
                        </button>
                        <button
                          type="button"
                          onClick={async () => {
                            await deleteRequest(request._id); 
                            getRequests();
                          }}
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white hover:bg-red-500 sm:mt-0 sm:w-auto"
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  )) : teacherRequests?.map((request) => (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={request._id}>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {request.name}
                      </th>
                      <td className="px-6 py-4">{request.email}</td>
                      <td className="px-6 py-4 flex gap-4">
                        <button
                          type="button"
                          onClick={async () => {
                            await onSignUp(
                              request.name,
                              request.email,
                              request.password,
                              "teacher",
                              "",
                              "",
                              user.school_id
                            );
                            await deleteRequest(request._id);
                            getRequests();
                          }}
                          className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                        >
                          Accept
                        </button>
                        <button
                          type="button"
                          onClick={async () => {
                            await deleteRequest(request._id); 
                            getRequests();
                          }}
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white hover:bg-red-500 sm:mt-0 sm:w-auto"
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-screen w-full">
              <p className="font-bold text-gray-700 -mt-48 truncate text-lg leading-5 mb-6">
                There are no requests at the moment.
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen w-full">
          <p className="font-bold text-gray-700 -mt-48 truncate text-lg leading-5 mb-6">
            You cannot access requests.
          </p>
        </div>
      )}
    </div>
  );  
};

export default RequestsPage;
