/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { selectCurrentUser } from "../../store/user/user.selector";
import { useSelector } from "react-redux";
import axios from "axios";

interface ChangeChapterProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    cid?: string,
    fetchLessonById: () => void;
    fetchLessonList: () => void;
}

const ChangeChapter: React.FC<ChangeChapterProps> = ({
    open,
    setOpen,
    cid,
    fetchLessonById,
    fetchLessonList
}) => {
    const user = useSelector(selectCurrentUser)
    const [chapter, setChapter] = useState<any>(null);
    const [chapterOptions, setChapterOptions] = useState<any>([]);
    const [letter, setLetter] = useState<string>("");
    const [standard, setStandard] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [chapterName, setChapterName] = useState<string>("");

    const chapterCount = async () => {
        const formData = new FormData();
        formData.append("standard", standard);
        formData.append("subject", subject);
        try {
          const res = await axios.post(
            "https://api.vidya-ai.com/get-chapter-names/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user.access_token}`,
              },
            }
          );
          const chapters = res.data.chapters;
          setChapterOptions(chapters);
          setChapter(chapters[0])
        } catch (error) {
            console.log(error);
        }
      };

      const fetchValues = async () => {
        try {
          const res = await axios.get(`https://api.vidya-ai.com/get-values/${cid}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          });
          setSubject(res.data.subject);
          setStandard(res.data.standard);
          setLetter(res.data.letter);
        } catch (error) {
            console.log(error);
        }
      };

      const changeChapter = async () => {
        if (chapterName) {
            try {
                const formData = new FormData()
                formData.append("chapter_name", chapterName)

                const res = await axios.patch(`https://api.vidya-ai.com/change-chapter/${chapter._id}`, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${user.access_token}`,
                    },
                  });

                console.log(res.data)
            } catch (error) {
                console.log(error)
            }
        }
      }



      useEffect(() => {
        fetchValues();
      }, []);
    
      useEffect(() => {
        chapterCount();
      }, [standard, subject]);

      return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            className="fixed inset-0 z-50"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />

            <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                    >
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start w-full">
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                    <DialogTitle
                                        as="h3"
                                        className="text-base font-semibold leading-6 text-gray-900"
                                    >
                                        Change Chapter Name
                                    </DialogTitle>
                                    <div className="mt-10 flex flex-col">
                                        <Menu as="div" className="relative inline-block text-left">
                                            <div className="flex items-center mb-4">
                                                <label
                                                htmlFor="chapter"
                                                className="text-sm font-bold leading-6 text-neutral-900 min-w-fit pr-4"
                                                >
                                                Change
                                                </label>
                                                <MenuButton className="flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                {chapter ? `${chapter.chapter}` : "Chapter"}
                                                <ChevronDownIcon
                                                    aria-hidden="true"
                                                    className="-mr-1 h-5 w-5 text-gray-400"
                                                />
                                                </MenuButton>
                                            </div>
                                            <MenuItems className="absolute right-0 z-50 top-10 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                                                <div className="max-h-[30vh] overflow-y-auto">
                                                    <div className="py-1">
                                                        {chapterOptions.map((chapter: any) => (
                                                        <MenuItem>
                                                            {({ active }) => (
                                                            <a
                                                                onClick={() => setChapter(chapter)}
                                                                className={`block px-4 py-2 text-sm ${
                                                                active ? "bg-gray-100" : ""
                                                                }`}
                                                            >
                                                                {chapter.chapter}
                                                            </a>
                                                            )}
                                                        </MenuItem>
                                                        ))}
                                                    </div>
                                                </div>
                                            </MenuItems>
                                        </Menu>
                                        <Menu as="div" className="relative inline-block text-left">
                                            <div className="flex items-center mb-4">
                                                <label
                                                htmlFor="chapter"
                                                className="text-sm font-bold leading-6 text-neutral-900 min-w-fit pr-4"
                                                >
                                                To
                                                </label>
                                                <input
                                                    type="text"
                                                    required
                                                    value={chapterName}
                                                    onChange={(e) => setChapterName(e.target.value)}
                                                    placeholder="Chapter name"
                                                    className="block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                                onClick={(e) => {
                                    e.preventDefault();
                                    changeChapter();
                                    setOpen(false)
                                }}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </button>
                        </div>  
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
      )
}

export default ChangeChapter
