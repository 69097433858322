import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import React, { ChangeEvent, MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import axios from "axios";
import { logout } from "../../store/user/user.reducer";
import { AppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";

interface EditProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lesson: any;
}

const Edit: React.FC<EditProps> = ({ open, setOpen, lesson }) => {
  const user = useSelector(selectCurrentUser);
  const [newSummary, setNewSummary] = useState<string>(lesson.summary || "");
  const dispatch: AppDispatch = useDispatch();

  const onLogOut = async () => {
    try {
      const resultAction = await dispatch(logout({ email: user.email }));
      if (logout.fulfilled.match(resultAction)) {
        console.log("Success:", resultAction.payload);
      } else {
        console.log("Failed to logout");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editSummary = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const formData = new FormData();
    if (newSummary !== lesson.summary) {
      formData.append("newSummary", newSummary);
      try {
        const res = await axios.patch(
          `https://api.vidya-ai.com/edit/${lesson._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );
        console.log(res);
        setOpen(false);
        window.location.reload();
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          alert("Session expired. Please log in again.");
          onLogOut();
        } else {
          console.log(error);
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="fixed inset-0 z-50"
    >
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 min-w-[50%] sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Edit Summary
                  </DialogTitle>
                  <div className="mt-2">
                    <form className="edit-form">
                      <textarea
                        placeholder="Edit Summary"
                        onChange={(e) => setNewSummary(e.target.value)}
                        value={newSummary}
                        className="min-h-[700px] min-w-[750px] overflow-y-auto p-2 border border-gray-300 rounded bg-white text-xs whitespace-pre-wrap text-black text-left"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                onClick={editSummary}
              >
                Save
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default Edit;
