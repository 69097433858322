import React, { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

type MessageType = "success" | "error";

interface Message {
  type: MessageType;
  text: string;
}

const ForgetPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<Message | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const sendEmail = async () => {
    try {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("email", email)
    
        const res = await axios.post('https://api.vidya-ai.com/send-email/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        console.log(res.data)
        setMessage({ type: "success", text: `Email sent!` });
    } catch (error) {
        setSubmitting(false);
        if (axios.isAxiosError(error) && error.response?.status === 404) {
            setMessage({ type: "error", text: `User does not exist.` });
            console.log(error)
          } else {
            setMessage({ type: "error", text: `An unexpected error has occurred.` });
            console.log(error);
          }
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendEmail()
  };

  return (
    <div className="grid grid-cols-2 h-screen w-screen">
      <div>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-left text-2xl font-bold leading-9 tracking-tight text-neutral-900">
              Forgot Password
            </h2>
            <p className="mt-5 text-left text-sm text-gray-500">
              Remember your pasword?{" "}
              <Link
                to="/login"
                className="font-bold leading-6 text-neutral-900 hover:text-amber-500"
              >
                Log in.
              </Link>
            </p>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="email"
                    className="block text-sm font-bold leading-6 text-neutral-900"
                  >
                    Email:
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {message && message.type === "error" && (
                <p className="font-semibold text-sm leading-6 text-red-600">
                  {message.text}
                </p>
              )}
              {message && message.type === "success" && (
                <p className="font-semibold text-sm leading-6 text-green-500">
                  {message.text}
                </p>
              )}
              <button
                type="submit"
                disabled={submitting}
                className="flex w-full justify-center rounded-md bg-amber-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {submitting ? (
                  <span className="animate-pulse">Submitting...</span>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-br from-amber-500 to-orange-500 flex items-center justify-center"></div>
    </div>
  );
};

export default ForgetPassword;
