// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./classroom.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    z-index: 0;
}

.background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the rgba values for the desired tint */
    z-index: -1; /* Make sure the tint is behind the content */
  }
  .lotus-image {
    position: absolute;
    top: 10%; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    width: 150px; /* Adjust size as needed */
    height: auto;
    z-index: 1; /* Ensure it's above the background but below the content */
  }`, "",{"version":3,"sources":["webpack://./src/routes/landing-page/landing-page.css"],"names":[],"mappings":"AAAA;IACI,yDAAwC;IACxC,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,aAAa;IACb,WAAW;IACX,UAAU;AACd;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,gDAAgD;IACtF,WAAW,EAAE,6CAA6C;EAC5D;EACA;IACE,kBAAkB;IAClB,QAAQ,EAAE,qBAAqB;IAC/B,SAAS;IACT,2BAA2B;IAC3B,YAAY,EAAE,0BAA0B;IACxC,YAAY;IACZ,UAAU,EAAE,2DAA2D;EACzE","sourcesContent":[".background {\n    background-image: url('./classroom.png');\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    height: 100vh;\n    width: 100%;\n    z-index: 0;\n}\n\n.background::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5); /* Adjust the rgba values for the desired tint */\n    z-index: -1; /* Make sure the tint is behind the content */\n  }\n  .lotus-image {\n    position: absolute;\n    top: 10%; /* Adjust as needed */\n    left: 50%;\n    transform: translateX(-50%);\n    width: 150px; /* Adjust size as needed */\n    height: auto;\n    z-index: 1; /* Ensure it's above the background but below the content */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
