import React, { FormEvent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

type MessageType = "success" | "error";

interface Message {
  type: MessageType;
  text: string;
}

const ChangePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [message, setMessage] = useState<Message | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userId, setUserId] = useState<string>(
      searchParams.get("userId") ?? ""
);

    const passwordNotMatched =
    password.length > 0 &&
    confirmPassword.length > 0 &&
    password !== confirmPassword;

  const changePassword = async () => {
    try {
        const formData = new FormData();
        formData.append("password", password)
        formData.append("user_id", userId)

        const res = await axios.patch("https://api.vidya-ai.com/change-password/", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        console.log(res.data)
        setMessage({ type: 'success', text: 'Changed password successfully!'})
        navigate("/login")
    } catch (error) {
        console.log(error)
        setMessage({ type: 'error', text: 'An unexpected error has occurred.'})
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    changePassword()
  };

  return (
    <div className="grid grid-cols-2 h-screen w-screen">
      <div>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-left text-2xl font-bold leading-9 tracking-tight text-neutral-900">
              Change Password
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
              <div className="flex items-center justify-between">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-bold leading-6 text-neutral-900"
                  >
                    Password:
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="flex items-center justify-between mt-6">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-bold leading-6 text-neutral-900"
                  >
                    Confirm Password:
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="password"
                    value={confirmPassword}
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {passwordNotMatched && (
                  <p className="text-red-500 text-xs mt-2 text-left">
                    Passwords does not match
                  </p>
                )}
              </div>
              {message && message.type === "error" && (
                <p className="font-semibold text-sm leading-6 text-red-600">
                  {message.text}
                </p>
              )}
              {message && message.type === "success" && (
                <p className="font-semibold text-sm leading-6 text-green-500">
                  {message.text}
                </p>
              )}
              <button
                type="submit"
                disabled={submitting}
                className="flex w-full justify-center rounded-md bg-amber-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {submitting ? (
                  <span className="animate-pulse">Submitting...</span>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-br from-amber-500 to-orange-500 flex items-center justify-center"></div>
    </div>
  );
};

export default ChangePassword;
