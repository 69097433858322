import React, { useState, useEffect } from "react";
import { selectCurrentUser } from "../../store/user/user.selector";
import { useSelector } from "react-redux";
import axios from "axios";

const UsersList = () => {
    const user = useSelector(selectCurrentUser)
    const [studentList, setStudentList] = useState<any>(null)
    const [teacherList, setTeacherList] = useState<any>(null)
    const [activeTab, setActiveTab] = useState<"student" | "teacher">(
        "student"
    );

    const getUsersList = async () => {
        try {
            const res = await axios.get('https://api.vidya-ai.com/get-users/', {
                headers: {
                  Authorization: `Bearer ${user.access_token}`,
                },
            });

            setStudentList(res.data.students)
            setTeacherList(res.data.teachers)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUsersList()
    }, [user])

    return (
        <div className="max-w-7xl mx-auto p-4">
            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <div className="flex justify-start mb-5">
                <div
                  className={`text-base text-gray-900 py-2 px-5 mx-1 cursor-pointer border-b-2 ${
                    activeTab === "student"
                      ? "border-black"
                      : "border-transparent"
                  } hover:border-gray-300`}
                  onClick={() => setActiveTab("student")}
                >
                  Student
                </div>
                    <div
                    className={`text-base text-gray-900 py-2 px-5 mx-1 cursor-pointer border-b-2 ${
                    activeTab === "teacher"
                        ? "border-black"
                        : "border-transparent"
                    } hover:border-gray-300`}
                    onClick={() => setActiveTab("teacher")}
                >
                    Teacher
                </div>
              </div>
            </div>
            <ul className="divide-y divide-gray-100 max-h-[32rem] overflow-y-auto">
                {activeTab === 'student' ? studentList?.map((person: any) => (
                    <li key={person.email} className="flex justify-between gap-x-6 py-5">
                        <div className="flex min-w-0 gap-x-4">
                            <img alt="" src={person.imageUrl} className="h-12 w-12 flex-none rounded-full bg-gray-50" />
                            <div className="min-w-0 flex-auto text-left">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{person.name}</p>
                                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email}</p>
                                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.standard}th Standard, Class {person.letter}</p>
                            </div>
                        </div>
                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                            <div className="mt-1 flex items-center gap-x-1.5">
                                <p className="text-xs leading-5 text-gray-500">{person.type.charAt(0).toUpperCase() + person.type.slice(1)}</p>
                            </div>
                        </div>
                    </li>
                )) : teacherList?.map((person: any) => (
                        <li key={person.email} className="flex justify-between gap-x-6 py-5">
                            <div className="flex min-w-0 gap-x-4">
                                <img alt="" src={person.imageUrl} className="h-12 w-12 flex-none rounded-full bg-gray-50" />
                                <div className="min-w-0 flex-auto text-left">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">{person.name}</p>
                                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email}</p>
                                </div>
                            </div>
                            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                <div className="mt-1 flex items-center gap-x-1.5">
                                    <p className="text-xs leading-5 text-gray-500">{person.type.charAt(0).toUpperCase() + person.type.slice(1)}</p>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
    
}

export default UsersList