import { RootState } from '../../store/store';
import { createSelector } from 'reselect';

const selectUser = (state: RootState) => state.user;

export const selectCurrentUser = createSelector(
    [selectUser],
    (userState) => userState.currentUser || { isAuthenticated: false } 
  );


